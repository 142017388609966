<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <div class="row align-center">
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-home"
                        class="mr-2"
                        @click="$router.push('/')"
                    />
                    <routerVue :breadcrumbsItems="breadcrumbsItems" />
                </div>
            </template>
        </DxItem>
    </DxToolbar>

    <div class="px-2 pb-5">
        <div :class="rowTitle">
            <i class="mdi mdi-circle-small"></i>
            Bước 1:
        </div>
        <img
            src="@/assets/images/HuongDanTaiLenhIphone/B1.png"
            class="anh-huong-dan"
            alt=""
        />

        <div :class="rowTitle">
            <i class="mdi mdi-circle-small"></i>
            Bước 2:
        </div>
        <img
            src="@/assets/images/HuongDanTaiLenhIphone/B2.png"
            class="anh-huong-dan"
            alt=""
        />

        <div :class="rowTitle">
            <i class="mdi mdi-circle-small"></i>
            Bước 3:
        </div>
        <img
            src="@/assets/images/HuongDanTaiLenhIphone/B3.png"
            class="anh-huong-dan"
            alt=""
        />

        <div :class="rowTitle">
            <i class="mdi mdi-circle-small"></i>
            Bước 4:
        </div>
        <img
            src="@/assets/images/HuongDanTaiLenhIphone/B4.png"
            class="anh-huong-dan"
            alt=""
        />
    </div>
</template>
<script>
import { DxToolbar, DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
        DxToolbar,
        DxItem,
        routerVue,
    },
    props: {},
    data() {
        return {
            breadcrumbsItems: [
                {
                    id: "breadcrums_TraCuuLenh",
                    text: "Hướng dẫn tải lệnh",
                    disabled: true,
                    to: "",
                },
            ],
            rowTitle: "row align-center mt-3 font-20 font-bold",
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.anh-huong-dan {
    border: 1px solid #000;
    width: 100%;
    height: auto;
}
</style>
